import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import {
  getAnalytics,
  logEvent as firebaseLogEvent,
  Analytics,
  isSupported,
} from "firebase/analytics";
import { app } from "../../firebase"; // Import your Firebase app instance

interface AnalyticsContextType {
  analytics: Analytics | null;
  logEvent: typeof firebaseLogEvent;
}

const AnalyticsContext = createContext<AnalyticsContextType | undefined>(
  undefined
);

interface AnalyticsProviderProps {
  children: ReactNode;
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
}) => {
  const [analytics, setAnalytics] = useState<Analytics | null>(null);

  useEffect(() => {
    const initializeAnalytics = async () => {
      if (typeof window !== "undefined" && (await isSupported())) {
        try {
          const analyticsInstance = getAnalytics(app);
          setAnalytics(analyticsInstance);
        } catch (error) {
          console.error("Failed to initialize analytics:", error);
        }
      }
    };

    initializeAnalytics();
  }, []);

  return (
    <AnalyticsContext.Provider
      value={{ analytics, logEvent: firebaseLogEvent }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error("useAnalytics must be used within an AnalyticsProvider");
  }
  return context;
};
