import React from "react";
import { AnalyticsProvider } from "./src/context/AnalyticsContext/AnalyticsContext";
import { navigate } from "gatsby";

export const onInitialClientRender = () => {
  if (typeof window !== "undefined") {
    const userLang = navigator.language;
    const pathname = window.location.pathname;
    const isMaintenance = process.env.GATSBY_SITE_MAINTENANCE === "true";
    //Maintenance redirection
    if (isMaintenance && userLang.startsWith("es")) {
      navigate("/es/maintenance", { replace: true });
    } else if (isMaintenance && userLang.startsWith("en")) {
      navigate("/en/maintenance", { replace: true });
    }
    // Handle root path "/"
    if (pathname === "/") {
      console.log("pathname is root / ");

      if (userLang.startsWith("es")) {
        navigate("/es/", { replace: true });
      } else {
        console.log("redirect to /en/");
        navigate("/en/", { replace: true });
      }
    }

    // Handle 404 path
    else if (pathname === "/404/") {
      const userLang = navigator.language;

      if (userLang.startsWith("es")) {
        navigate("/es/404/", { replace: true });
      } else if (userLang.startsWith("es")) {
        navigate("/en/404/", { replace: true });
      }
    }
  }
};

export const wrapRootElement = ({ element }) => {
  return <AnalyticsProvider>{element}</AnalyticsProvider>;
};
